const heroSwiper = new Swiper('.top-hero-container', {
  direction: 'vertical',
  slidesPerView: 'auto',
  freeMode: true,
  // mousewheel: {
  //   enabled: true,
  //   releaseOnEdges: true,
  // },
  loop: true,
  speed: 120000,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
    stopOnLastSlide: true,
  },
  allowTouchMove: false,
  // on: {
  //   scroll: () => {
  //     heroSwiper.autoplay.stop();
  //   },
  // },
});

const pickupSwiper = new Swiper('.top-pickup-container', {
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'progressbar',
  },
});
